import React from 'react';
import * as S from './styled';
import { ContactCardSocial } from './ContactCardSocial';
import { ContactCardHeader } from './ContactCardHeader';
import { ContactCardActions, IvCard } from './ContactCardActions';
import { ContactCardPersonal } from './ContactCardPersonal';
import { ContactCardMainAction } from './ContactCardMainAction';

interface IContactCard {
  contact: IvCard;
}
const ContactCard = (props: IContactCard) => {
  const background = '#fff';
  const color = '#30429A';
  const person = props.contact;
  return (
    <S.ContactCardContainer>
      <ContactCardHeader color={color} background={background} />
      <ContactCardActions
        color={color}
        name={person.name}
        position={person.position}
        phone={person.phone}
        vCardInfo={person}
        direction_url={person.direction_url}
      />
      <ContactCardPersonal phone={person.phone} email={person.email} address={person.address} />
      <ContactCardSocial
        color="#212947"
        linkedIn={person.linkedin}
        twitter="https://twitter.com/craftablesw"
        website="https://www.craftablesoftware.com/"
      />
      <ContactCardMainAction color={color} email={person.email} />
    </S.ContactCardContainer>
  );
};

export default ContactCard;
