import React from 'react';
import * as S from '../styled';
import Icon from '../../Common/Icon';
import { isBlank } from '../../../utils/helpers';
import { ContactCardActionSize, ContactCardWidthSize } from '../ContactCardActions';

interface IContactCardSocial {
  color: string;
  twitter?: string;
  website?: string;
  linkedIn?: string;
}

export const ContactCardSocial = (props: IContactCardSocial) => {
  const { color, twitter, website, linkedIn } = props;

  return (
    <S.CardWidth size={ContactCardWidthSize.small}>
      <S.ContactCardSocials>
        {!isBlank(linkedIn) && (
          <a href={linkedIn} target="_blank">
            <S.ContactCardAction
              actionSize={ContactCardActionSize.small}
              iconWidth={20 * 1.4}
              iconHeight={20 * 1.4}
              color={color}
              background="white"
            >
              <Icon name="contact-card-linked_in" />
            </S.ContactCardAction>
          </a>
        )}
        {!isBlank(twitter) && (
          <a href={twitter} target="_blank">
            <S.ContactCardAction
              actionSize={ContactCardActionSize.small}
              iconWidth={28 * 1.4}
              iconHeight={20.94 * 1.4}
              color={color}
              background="white"
            >
              <Icon name="contact-card-xtwitter" />
            </S.ContactCardAction>
          </a>
        )}
        {!isBlank(website) && (
          <a href={website} target="_blank">
            <S.ContactCardAction
              actionSize={ContactCardActionSize.small}
              iconWidth={20 * 1.4}
              iconHeight={20 * 1.4}
              color={color}
              background="white"
            >
              <Icon name="contact-card-globe" />
            </S.ContactCardAction>
          </a>
        )}
      </S.ContactCardSocials>
    </S.CardWidth>
  );
};
