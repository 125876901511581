import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import ContactCard from '../components/ContactCard';
import Layout from '../components/Common/Layout';
import Seo from '../components/Common/Seo';
import { initializeGa4, sendPageView } from '../utils/ga4';
import { initializeHotjar } from '../utils/hotjar';

export default (props) => {
  
  useEffect(() => {
    initializeHotjar();
    initializeGa4();
    sendPageView({
      page:`/${props.params.slug}`, 
      title:"Contact"
    });
  }, []);

  const { contact } = props.data;
  return (
    <Layout hash={props?.location?.hash} discret={true}>
      <Seo
        title="software with purpose"
        description="craftable software specialises in systems that require quality, reliability and scalability in a highly transactional environment for complex software ecosystems."
      />
      <ContactCard contact={contact} />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contact(id: { eq: $id }) {
      slug
      name
      city
      zip
      country
      phoneNumber
      phone
      position
      company
      website
      email
      address
      direction_url
      linkedin
    }
  }
`;
