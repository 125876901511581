import React from 'react';
import * as S from  '../styled';
import { isBlank } from '../../../utils/helpers';
import Icon from '../../Common/Icon';
import { ContactCardActionSize, ContactCardWidthSize } from '../ContactCardActions';

interface IContactCardMainAction {
    color: string;
    email?: string;
}

export const ContactCardMainAction = (props: IContactCardMainAction) => {
    const { email, color } = props;
    
    return (
        <S.CardWidth size={ContactCardWidthSize.big}>
            <S.ContactCardMainActions>
                {!isBlank(email) && (
                    <a href={`mailto:${email}`}>
                        <S.ContactCardAction 
                            actionSize={ContactCardActionSize.biggest}
                            iconWidth={47} 
                            iconHeight={59} 
                            color={color}
                            background="#30429A"
                        >
                            <Icon name="contact-card-mail" />  
                        </S.ContactCardAction>
                    </a>
                )}
            </S.ContactCardMainActions>
        </S.CardWidth>
    );
}