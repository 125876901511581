import React from 'react';
import * as S from '../styled';
import { isBlank } from '../../../utils/helpers';
import Icon from '../../Common/Icon';
import VCard from 'vcard-creator';
import { logo_base64 } from '../data';

export interface IvCard {
  slug: string;
  name: string;
  city: string;
  zip: string;
  country: string;
  phoneNumber: number;
  phone: string;
  position: string;
  company: string;
  website: string;
  email: string;
  address: string;
  direction_url: string;
  linkedin: string;
}

interface IContactCardHeader {
  name: string;
  position: string;
  color: string;
  phone?: string;
  vCardInfo?: IvCard;
  direction_url?: string;
}

export enum ContactCardWidthSize {
  small = 184,
  big = 352,
}

export enum ContactCardActionSize {
  small = 32 * 1.4,
  big = 64,
  biggest = 84,
}

export const ContactCardActions = (props: IContactCardHeader) => {
  const { name, position, phone, vCardInfo, direction_url, color } = props;
  const createVCard = () => {
    return new VCard()
      .addEmail(vCardInfo.email)
      .addCompany(vCardInfo.company)
      .addJobtitle(vCardInfo.position)
      .addURL(vCardInfo.website)
      .addPhoneNumber(vCardInfo.phoneNumber, 'PREF;WORK')
      .addName(vCardInfo.name.split(' ')[1], vCardInfo.name.split(' ')[0])
      .addAddress(
        '',
        '',
        vCardInfo.address,
        vCardInfo.city,
        vCardInfo.city,
        vCardInfo.zip,
        vCardInfo.country
      )
      .addPhoto(logo_base64, 'JPEG');
  };
  const card = createVCard().toString();
  const downloadVCard = () => {
    const file = new Blob([card], { type: 'text/vcard' });
    const textFile = window.URL.createObjectURL(file);
    const link = document.createElement('a') as HTMLAnchorElement;
    link.href = textFile;
    link.download = `${vCardInfo.name.split(' ')[0]}.vcf`;
    link.click();
    link.remove();
  };
  return (
    <S.CardWidth size={ContactCardWidthSize.big}>
      <S.ContactHeaderInfo>
        <h1>{name}</h1>
        <h2>{position}</h2>
      </S.ContactHeaderInfo>
      <S.ConactCardDialog>
        <h3>HELLO!</h3>
      </S.ConactCardDialog>
      <S.ContactCardActions>
        {!isBlank(phone) && (
          <a href={`tel:${phone}`}>
            <S.ContactCardAction
              actionSize={ContactCardActionSize.big}
              iconWidth={32}
              iconHeight={35}
              color={color}
              background="white"
            >
              <Icon name="contact-card-phone" />
            </S.ContactCardAction>
          </a>
        )}
        {!isBlank(direction_url) && (
          <a href={direction_url} target="_blank">
            <S.ContactCardAction
              actionSize={ContactCardActionSize.big}
              iconWidth={35}
              iconHeight={35}
              color={color}
              background="white"
            >
              <Icon name="contact-card-route" />
            </S.ContactCardAction>
          </a>
        )}
        <a>
          <S.ContactCardAction
            onClick={() => downloadVCard()}
            actionSize={ContactCardActionSize.big}
            iconWidth={47.84}
            iconHeight={30}
            color={color}
            background="white"
          >
            <Icon name="contact-card-vcard" />
          </S.ContactCardAction>
        </a>
      </S.ContactCardActions>
    </S.CardWidth>
  );
};
