import React from 'react';
import * as S from  '../styled';
import Icon from '../../Common/Icon';
import { Link } from 'gatsby';
import { ContactCardWidthSize } from '../ContactCardActions';

interface IContactCardHeader {
    color: string;
    background: string;
}

export const ContactCardHeader = (props: IContactCardHeader) => {
    const { color, background } = props;
    return (
        <S.ContactCardHeader 
            background={background} 
            color={color}
        >
            <S.CardWidth size={ContactCardWidthSize.big}>
                <Link to="/">
                    <S.ContactCardHeaderLogo color={"#212947"}>
                        <Icon name="craftable-darkblue-logo" />
                    </S.ContactCardHeaderLogo>
                </Link>
            </S.CardWidth>
        </S.ContactCardHeader>
    );
}
