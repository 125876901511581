import { bootstrapBreakpoints } from '../../mock/data';
import styled from 'styled-components';
import { pxTovwConverter } from '../../utils/breakpoints';

export const ContactCardContainer = styled.div`
    position: relative;
`; 

interface ICardWidth {
    size: string;
};

export const CardWidth = styled.div<ICardWidth>`
    display:block;
    margin: 0 auto;
    position: relative;
    max-width: ${props => pxTovwConverter(bootstrapBreakpoints.xs, props.size)};
    @media (min-width: ${bootstrapBreakpoints.xs}px){
        max-width: ${props => `${props.size}px`};
    }
    @media (min-width: ${bootstrapBreakpoints.md}px){
        width: 800px;
        max-width: 85%;
    }
`; 

interface IContactCardHeader {
    background: string;
    color: string;
};

export const ContactCardHeader = styled.div<IContactCardHeader>`
    color: ${props => props.color};
    background: ${props => props.background};
    padding-top: ${pxTovwConverter(bootstrapBreakpoints.xs, 76)};
    padding-bottom: ${pxTovwConverter(bootstrapBreakpoints.xs, 61)};
    @media (min-width: ${bootstrapBreakpoints.xs}px){
        padding-top: 76px;
        padding-bottom: 61px;    
    }
    @media (min-width: ${bootstrapBreakpoints.md}px){
        padding-top: 100px;
        padding-bottom: 86px;   
    }
`;

interface IContactCardHeaderLogo {
    color: string;
};

export const ContactCardHeaderLogo = styled.div<IContactCardHeaderLogo>`
    svg{
        width: ${pxTovwConverter(bootstrapBreakpoints.xs, 224)};
        height: ${pxTovwConverter(bootstrapBreakpoints.xs, 58)};
        fill: ${props => props.color};
        @media (min-width: ${bootstrapBreakpoints.xs}px){
            width: 224px;
            height: 58px;
        }
        @media (min-width: ${bootstrapBreakpoints.md}px){
            width: 400px;
            height: 103.5px;
        }
    }
`;

export const ConactCardDialog = styled.div`
    top: 0;
    right: 0;
    display: flex;
    color: #48FFBF;
    background:#30429A;
    justify-content:center;
    align-items: center;
    position: absolute;
    transform: translateY(-50%);
    width: ${pxTovwConverter(bootstrapBreakpoints.xs, 104)};
    height: ${pxTovwConverter(bootstrapBreakpoints.xs, 54)};
    clip-path: polygon(0 0,
                        100% 0,
                        100% calc(100% - ${pxTovwConverter(bootstrapBreakpoints.xs, 7.5)}),
                        ${pxTovwConverter(bootstrapBreakpoints.xs, 36)} calc(100% - ${pxTovwConverter(bootstrapBreakpoints.xs, 7.5)}),
                        ${pxTovwConverter(bootstrapBreakpoints.xs, 28)} 100%,
                        ${pxTovwConverter(bootstrapBreakpoints.xs, 24)} calc(100% - ${pxTovwConverter(bootstrapBreakpoints.xs, 7.5)}),
                        0 calc(100% - ${pxTovwConverter(bootstrapBreakpoints.xs, 7.5)}),
                        0 0);
    @media (min-width: ${bootstrapBreakpoints.xs}px){
        width: 104px;
        height: 54px;
        clip-path: polygon(0 0,
                           100% 0,
                           100% calc(100% - 7.5px),
                           36px calc(100% - 7.5px),
                           28px 100%,
                           24px calc(100% - 7.5px),
                           0 calc(100% - 7.5px),
                           0 0);
    }
    @media (min-width: ${bootstrapBreakpoints.md}px){
        width: 144px;
        height: 72px;
        clip-path: polygon(0 0,
                           100% 0,
                           100% calc(100% - 10.5px),
                           45px calc(100% - 10.5px),
                           32px 100%,
                           30px calc(100% - 10.5px),
                           0 calc(100% - 10.5px),
                           0 0);
    }
    h3{
        margin: 0;
        font-weight: 700;
        font-size:  ${pxTovwConverter(bootstrapBreakpoints.xs, 20)};
        line-height:  ${pxTovwConverter(bootstrapBreakpoints.xs, 27)};
        transform: translateY( ${pxTovwConverter(bootstrapBreakpoints.xs, -3)});
        @media (min-width: ${bootstrapBreakpoints.xs}px){
            font-size: 20px;
            line-height: 27px;
            transform: translateY(-3px);
        }
        @media (min-width: ${bootstrapBreakpoints.md}px){
            font-size: 27px;
            line-height: 27px;
            transform: translateY(-3px);
        }
    }

`;

export const ContactHeaderInfo = styled.div`
    color: white;
    text-align:left;
    padding-top: ${pxTovwConverter(bootstrapBreakpoints.xs, 48)};
    padding-bottom: ${pxTovwConverter(bootstrapBreakpoints.xs, 56)};
    letter-spacing: ${pxTovwConverter(bootstrapBreakpoints.xs, 2.99)};
    @media (min-width: ${bootstrapBreakpoints.xs}px){
        padding-top: 48px;
        padding-bottom: 56px;
        letter-spacing: 2.99px;
    }
    @media (min-width: ${bootstrapBreakpoints.md}px){
        padding-top: 68px;
        padding-bottom: 80px;
        letter-spacing: 2.99px;
    }
    * {
        margin:0
    }
    h1{
        font-weight: 700;
        font-size: ${pxTovwConverter(bootstrapBreakpoints.xs, 30)};
        line-height: ${pxTovwConverter(bootstrapBreakpoints.xs, 35)};
        @media (min-width: ${bootstrapBreakpoints.xs}px){
            font-size: 30px;
            line-height: 35px;
        }
        @media (min-width: ${bootstrapBreakpoints.md}px){
            font-size: 40px;
            line-height: 45px;
        }
    }
    h2{
        font-family: Darwin Light;
        font-size: ${pxTovwConverter(bootstrapBreakpoints.xs, 20)};
        line-height: ${pxTovwConverter(bootstrapBreakpoints.xs, 30)};
        @media (min-width: ${bootstrapBreakpoints.xs}px){
            font-size: 20px;
            line-height: 30px;
        }
        @media (min-width: ${bootstrapBreakpoints.md}px){
            font-size: 27px;
            line-height: 40px;
        }
    }
`;

export const ContactCardPersonal = styled.div`
    color: white;
    text-align: left;
`;

export const ContactCardPersonalItem = styled.div`
    margin-top: ${pxTovwConverter(bootstrapBreakpoints.xs, 32)};
    @media (min-width: ${bootstrapBreakpoints.xs}px){
        margin-top: 32px;
    }
    @media (min-width: ${bootstrapBreakpoints.md}px){
        margin-top: 60px;
    }
    input{
        width: 100%;
        color: white;
        border: none;
        font-weight: 400;
        background: #60698B;
        font-size: ${pxTovwConverter(bootstrapBreakpoints.xs, 20)};
        line-height: ${pxTovwConverter(bootstrapBreakpoints.xs, 25)};
        border-radius: ${pxTovwConverter(bootstrapBreakpoints.xs, 8)};
        padding: ${pxTovwConverter(bootstrapBreakpoints.xs, 20)} ${pxTovwConverter(bootstrapBreakpoints.xs, 13)};
        @media (min-width: ${bootstrapBreakpoints.xs}px){
            font-size: 20px;
            line-height: 25px;
            border-radius: 8px;
            padding: 20px 13px;
        }
        @media (min-width: ${bootstrapBreakpoints.md}px){
            font-size: 27px;
            line-height: 35px;
            border-radius: 8px;
            padding: 28px 18px;
        }
    }
`;

export const ContactCardActions = styled.div`
    display: flex;
    justify-content: space-between;
    > a {
        &:first-of-type{
            margin-left: ${pxTovwConverter(bootstrapBreakpoints.xs, 23)};
            @media (min-width: ${bootstrapBreakpoints.xs}px){
                margin-left: 23px;
            }
            @media (min-width: ${bootstrapBreakpoints.md}px){
                margin-left: 55px;
            }
        }
        &:last-of-type{
            margin-right: ${pxTovwConverter(bootstrapBreakpoints.xs, 23)};
            @media (min-width: ${bootstrapBreakpoints.xs}px){
                margin-right: 23px;
            }
            @media (min-width: ${bootstrapBreakpoints.md}px){
                margin-right: 55px;
            }
        }
    }
`;

export const ContactCardMainActions = styled.div`
    width: 100%;
    display: flex;
    align-items: right;
    justify-content: right;
    padding: ${pxTovwConverter(bootstrapBreakpoints.xs, 64)} 0 ${pxTovwConverter(bootstrapBreakpoints.xs, 24)};
    @media (min-width: ${bootstrapBreakpoints.xs}px){
        padding: 64px 0 24px;
    }
    @media (min-width: ${bootstrapBreakpoints.md}px){
        padding: 100px 0 40px;
    }
`;

export const ContactCardSocials = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: ${pxTovwConverter(bootstrapBreakpoints.xs, 32)};
    @media (min-width: ${bootstrapBreakpoints.xs}px){
        padding-top: 32px;
    }
    @media (min-width: ${bootstrapBreakpoints.md}px){
        padding-top: 55px;
    }
    > a {
        &:first-of-type{
            margin-left: ${pxTovwConverter(bootstrapBreakpoints.xs, -30)};
            @media (min-width: ${bootstrapBreakpoints.xs}px){
                margin-left: -30px;
            }
            @media (min-width: ${bootstrapBreakpoints.md}px){
                margin-left: 150px;
            }
        }
        &:last-of-type{
            margin-right: ${pxTovwConverter(bootstrapBreakpoints.xs, -30)};
            @media (min-width: ${bootstrapBreakpoints.xs}px){
                margin-right: -30px;
            }
            @media (min-width: ${bootstrapBreakpoints.md}px){
                margin-right: 150px;
            }
           
        }
    }
`;

interface IContactCardAction {
    color: string;
    iconWidth: number;
    iconHeight: number;
    number: string;
    background: string;
};

export const ContactCardAction = styled.div<IContactCardAction>`
    display: flex;
    background: ${props => props.background};
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: ${props => pxTovwConverter(bootstrapBreakpoints.xs, props.actionSize)};
    height: ${props => pxTovwConverter(bootstrapBreakpoints.xs, props.actionSize)};    
    @media (min-width: ${bootstrapBreakpoints.xs}px){
        width: ${props => `${props.actionSize}px`};
        height: ${props => `${props.actionSize}px`};
    }
    @media (min-width: ${bootstrapBreakpoints.md}px){
        width: ${props => `${props.actionSize*1.40}px`};
        height: ${props => `${props.actionSize*1.40}px`};
    }
    svg{
        fill: ${props => props.color};
        width: ${props => pxTovwConverter(bootstrapBreakpoints.xs, props.iconWidth)}; 
        height: ${props => pxTovwConverter(bootstrapBreakpoints.xs, props.iconHeight)};
        @media (min-width: ${bootstrapBreakpoints.xs}px){
            width: ${props => `${props.iconWidth}px`}; 
            height: ${props => `${props.iconHeight}px`};
        }
        @media (min-width: ${bootstrapBreakpoints.md}px){
            width: ${props => `${props.iconWidth*1.40}px`}; 
            height: ${props => `${props.iconHeight*1.40}px`};
        }
    }
`;